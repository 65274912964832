import { post , get } from '../request'

const geom = {
    // 首页地磁设备实时数据
    getDevParkChange:(data) => post('/geom/exec/geomWeChatApps/getDevParkChange',data),
    // 全部设备信息
    allDevInfo:(data) => post('/geom/exec/geomWeChatApps/getDevList',data),
    // 停车状态历史数据
    getGeomDevParkChange:(pageNum,pageSize,data) => post(`/geom/exec/geomWeChatApps/getGeomDevParkChange?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 停车状态图表数据
    getDateCharts:(data) => post('/geom/exec/geomWeChatApps/getDateCharts',data),
}

export default geom
