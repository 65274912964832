import { post , get } from '../request'

const jcw = {
    // 设备信息列表
    deviceInfoList:(data) => post('/jcw/exec/wxDeviceBaseInfo/getDevStatus',data),
    // 历史数据
    historyInfoPage:(pageNum,pageSize,data) => post(`/jcw/jcwMonitorCollection/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 根据设备编号查询实时信息
    realInfoByDevCode:(devCode) => get(`/jcw/exec/jcwMonitorCollection/get/${devCode}`),
    // 告警列表
    alarmPageList:(pageNum,pageSize,data) => post(`/jcw/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 处理告警
    handleAlarm:(data) => post(`/alarm/cancelTips`,data)
}

export default jcw
