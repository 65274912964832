const homeIndex = () => import ('@/views/bucket/Index.vue');
const home = () => import ('@/views/bucket/home/Index.vue');
const param = () => import ('@/views/bucket/param/Index.vue');
const list = () => import ('@/views/bucket/list/Index.vue');
const about = () => import ('@/views/bucket/about/Index.vue');


const bucketRouter = [
    { path: '/bucket',component: homeIndex,children:[
        { path: '/',component: home,meta:{title:'安全哨卫'}},
        { path: 'list',component: list},
        { path: 'param',component: param},
        { path: 'about',component: about},
    ]}
]

export default bucketRouter
