const homeIndex = () => import ('@/views/melves/Index.vue');
const home = () => import ('@/views/melves/home/Index.vue');
const details = () => import ('@/views/melves/home/Details.vue');
const statistics = () => import ('@/views/melves/statistics/Index.vue');
const history = () => import ('@/views/melves/history/Index.vue');


const melvesRouter = [
    { path: '/melves',component: homeIndex,children:[
        { path: '/',component: home,meta:{title:'气象精灵系统'}},
        { path: 'details',component: details},
        { path: 'statistics',component: statistics},
        { path: 'history',component: history},
    ]}
]

export default melvesRouter
