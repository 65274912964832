const homeIndex = () => import ('@/views/pah/Index.vue');
const home = () => import ('@/views/pah/home/Home.vue');
const devIndex = () => import ('@/views/pah/dev/Index.vue');
const devList = () => import ('@/views/pah/dev/List.vue');
const devDetail = () => import('@/views/pah/dev/Detail.vue');
const devVideo = () => import('@/views/pah/dev/Video.vue');
const about = () => import('@/views/pah/about/Index.vue');
const explain = () => import('@/views/pah/about/Explain.vue');
const intro = () => import('@/views/pah/about/Intro.vue');

const pahRouter = [
    { path: '/pah',component: homeIndex,meta:{title:'平安花'},children:[
        { path: '/',component: home,meta:{title:'平安花'}},
        { path: 'dev',component: devIndex,children:[
            { path: '/',name: 'devIndex',component: devList,meta:{title:'设备列表'}},
            { path: 'detail',name: 'devDetail',component: devDetail,meta:{title:'设备详情'}},
            { path: 'video',name: 'video',component: devVideo,meta:{title:'实时视频'}},
        ]},
        { path: 'about',component: about,meta:{title:'关于'}},
        { path: 'explain',component: explain,meta:{title:'产品说明'}},
        { path: 'intro',component: intro,meta:{title:'产品介绍'}}
    ]}
]

export default pahRouter
