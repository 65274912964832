<template>
    <div class="login">
        <!-- <div class="top-img">
            <img src="@/assets/home/bg-login.png">
        </div> -->
        <div class="content-area">
            <div class="logo">智慧云平台</div>
            <div class="middle">
                <div class="block-input-row">
                    <input class="input-area" type="text" v-model="form.user" maxlength="20" placeholder="请输入账号">
                </div>
                <div class="block-input-row">
                    <input class="input-area" type="password" v-model="form.pwd" maxlength="20" placeholder="请输入密码">
                </div>
            </div>
            <div class="hint" v-if="OPENID && OPENID !== 'null'">
                <van-checkbox v-model="binding" checked-color="#20c7d8">绑定微信账号</van-checkbox>
            </div>
            <div class="handle-area">
                <div class="btn" @click="userLogin">立即登录</div>
                <div class="btn-border" @click="visitorLogin">体验访问</div>
            </div>
        </div>
        <div class="version">版本号@1.1.0</div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            form:{
                user:window.localStorage.getItem('userAccount') || '',
                pwd:window.localStorage.getItem('userPwd') || ''
            },
            binding:true,
            OPENID:null
        }
    },
    mounted(){
        this.OPENID = window.localStorage.getItem('OPENID');
    },
    methods:{
        // 用户登录
        userLogin(){
            if(!this.form.user || !this.form.pwd){
				return this.$toast('用户名或密码不能为空！')
            }
            this.ssoLogin().then( ticket => {
                this.ticketDoLogin(ticket)
            })
        },
        // 统一登录
        ssoLogin(){
            axios.defaults.baseURL='/';
            return new Promise( (resolve,reject) => {
                axios.get('/ssoAgent/sso/doLogin', {
                    header: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    params: {
                        name: this.form.user,
                        pwd: this.form.pwd
                    }
                })
                .then( (res) => {
                    if (res.code == 200){
                        resolve(res.data);
                    } else {
                        this.$toast(res.msg)
                        reject();
                    }
                })
            })
        },
		// ticket换取登录
		ticketDoLogin(ticket){
            axios.defaults.baseURL='/weixin';
            this.$api.SSO.doLoginByTicket({
                ticket:ticket,
                openId:this.OPENID,
                bindFlag:this.binding && this.OPENID && this.OPENID !== 'null' ? 1 : 0
            }).then( d => {
                window.localStorage.setItem('Tit-token', d);
                // 记忆上次登录账户和密码
                window.localStorage.setItem('userAccount',this.form.user);
                window.localStorage.setItem('userPwd',this.form.pwd);

                this.$toast('登录成功')
                setTimeout(()=>{
                    this.$router.push({path:'/'})
                },1000)
            })
		},
        // 游客登录
        visitorLogin(){
            axios.defaults.baseURL='/weixin';
            this.$api.SSO.doWxLoginByOpenId({ openId: this.OPENID }).then(res =>{
                window.localStorage.setItem('Tit-token',res);
                window.localStorage.setItem('visitor',1)  // 标记-游客模式
                setTimeout(()=>{
                    this.$router.push({path:'/'})
                },500)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.login{
    // background-image: linear-gradient(148deg, #3156ac 25%, #67a9dc 51%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    background: url('../assets/home/bg1-login.png') no-repeat;
    background-size: 100% auto;
    background-color: #3959AE;
    .top-img{
        img{
            width: 100%;
            height: 100%;
        }
    }
    .content-area{
        padding: 10px 10px 30px 10px;
        background: #ffffff40;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        border-radius: 10px;
    }
    .version{
        position: absolute;
        bottom: 5px;
        width: 100%;
        text-align: center;
        font-size: .7rem;
        color: #ececec;
    }
    .logo{
        color: #fff;
        font-weight: 600;
        font-size: 1.65rem;
        letter-spacing: 3px;
        padding: 30px 0;
        text-shadow: 2px 2px 3px #1e1e1e;
        transform: scaleX(1) scaleY(0.9);
    }
    .middle{
        padding: 0 30px;
        .block-input-row{
            padding-bottom:20px;
            // display: flex;
            // align-items: center;
            // border: 1px solid #fff;
            // border-radius: 20px;
            // line-height: 2.8;
            // color: #f3f3f3;
            // margin-bottom: 20px;
            // .label{
            //     width: 25%;
            // }
            .input-area{
                width: calc(100% - 20px);
                padding: 10px;
                border-radius: 5px;
                border: none;
                input{
                    width: 100%;
                    background-color: #0000;
                    border: none;
                    letter-spacing: 1px;
                    &::-webkit-input-placeholder {
                        color: #f1f1f1;
                    }
                    &:-moz-placeholder {
                        color: #f1f1f1;
                    }
                    &:-ms-input-placeholder {
                        color: #f1f1f1;
                    }
                }
            }
        }
    }
    .hint{
        padding: 0 32px;
        display: flex;
    }
    .handle-area{
        margin-top:20px;
        padding: 0 30px;
        font-size: .85rem;
        .btn{
            background: #429bf7;
            color: #fff;
            border-radius: 10px;
            line-height: 2.5;
            letter-spacing: 1px;
            margin-bottom: 20px;
            padding: 5px;
            &:active{
                opacity: 0.6;
            }
        }
        .btn-border{
            background: #838383;
            color: #fff;
            border-radius: 10px;
            line-height: 2.5;
            letter-spacing: 1px;
            padding: 5px;
            &:active{
                opacity: 0.6;
            }
        }
    }
}
</style>