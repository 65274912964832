<template>
    <div class="home-project">
        <div class="flex-between">
            <div class="title-big">平台列表</div>
            <van-icon name="setting" class="setting-icon" @click="goSetting" />
        </div>
        <div class="title-small">PLATFORM LIST</div>
        <van-row>
            <div v-if="loading">
                <van-loading type="spinner" color="#1989fa" style="line-height: 300px" />
            </div>
            <div v-else>
                <van-col span="12" class="block" v-for="(item,index) in showList" @click="goProject(item)" :key="index">
                    <div class="logo"><img :src="item.logo"></div>
                    <div class="title">{{item.title}}</div>
                </van-col>
            </div>
        </van-row>
        <!-- <div class="handle-area">
            <div class="btn-setting" @click="goSetting">设置</div>
        </div> -->
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading:true,
            projectList:[
                {
                    logo:require('../assets/home/wlms.jpg'),
                    title:'水位监测',
                    path:'wlms'
                },
                {
                    logo:require('../assets/home/melves.jpg'),
                    title:'气象监测',
                    path:'melves'
                },
                {
                    logo:require('../assets/home/bridge.jpg'),
                    title:'桥梁健康监测',
                    path:'bridge'
                },
                {
                    logo:require('../assets/home/slope.jpg'),
                    title:'边坡健康监测',
                    path:'slope'
                },
                {
                    logo:require('../assets/home/swjl.jpg'),
                    title:'守望精灵',
                    path:'swjl'
                },
                {
                    logo:require('../assets/home/fog.jpg'),
                    title:'团雾监测',
                    path:'fog'
                },
                {
                    logo:require('../assets/home/wgs.jpg'),
                    title:'智慧护栏',
                    path:'wgs'
                },
                {
                    logo:require('../assets/home/bucket.jpg'),
                    title:'安全哨卫',
                    path:'bucket'
                },
                {
                    logo:require('../assets/home/jcw.jpg'),
                    title:'接触网结冰检测',
                    path:'jcw'
                },
                {
                    logo:require('../assets/home/itgs.jpg'),
                    title:'智慧路桩',
                    path:'itgs'
                },
                {
                    logo:require('../assets/home/pah.jpg'),
                    title:'平安花安全提醒',
                    path:'pah'
                },
                
                {
                    logo:require('../assets/home/icps.jpg'),
                    title:'智能供电包',
                    path:'icps'
                },
                {
                    logo:require('../assets/home/geom.jpg'),
                    title:'地埋式车检器',
                    path:'geom'
                },
                {
                    logo:require('../assets/home/720cam.jpg'),
                    title:'围界监测',
                    path:'720cam'
                },
                {
                    logo:require('../assets/home/tunnel.jpg'),
                    title:'隧道管理',
                    path:'tunnel'
                },
            ],
            showList:[]
        }
    },
    methods:{
        // 跳转到项目页
        goProject(item){
            if(item.path === "tunnel"){
                this.$router.push({path:'/tunnel'})
            }else{
                this.$router.push({path:'/project',query:{path:item.path}})
            }
        },
        // 获取列表
        getList(path){
            this.$api.SSO.getProjectList(path,{}).then( d => {
                this.loading = false;
                let arr = [];
                for(let key in d){
                  //  console.warn(key);
                    d[key].length && arr.push(key)
                }
                this.showList = this.projectList.reduce( (acc,cur) => {
                    arr.map( v => { cur.path === v && acc.push(cur)})
                    return acc
                },[])
            })
        },
        // 去设置
        goSetting(){
            this.$router.push({path:'/setting'})
        },
        getAuthTags(){
            this.$api.SSO.getPermission({}).then( d => {
                window.localStorage.setItem('authTags',d);
            })
        }
    },
    mounted(){
        this.getList('all');
        this.getAuthTags();
    }
}
</script>

<style lang="scss" scoped>
.home-project{
    // background-image: linear-gradient(141deg,#affbda 0%,#1fc8db 51%,#2cb5e8 75%);
    // background: linear-gradient(141deg, #33bccc 51%, #4196bf 75%);
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    overflow-y: auto;
    .block{
        position: relative;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 5px;
        width: calc(50% - 10px);
        .logo{
            img{
                width: 100%;
                height: 100%;
                vertical-align: middle;
                height: 118px;
            }
            .img-0{
                height: calc(216px + 10px);
            }
        }
        .title{
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: #323548a3;
            color: #e4e4e4;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            font-size: .65rem;
            line-height: 2;
        }
    }
    // .handle-area{
    //     display: flex;
    //     justify-content: center;
    //     margin-top: 15px;
    //     .btn-setting{
    //         background-color: #2a90e2;
    //         color: #fff;
    //         line-height: 38px;
    //         border-radius: 10px;
    //         letter-spacing: 2px;
    //         font-size: .85rem;
    //         width: 95%;
    //         &:active{
    //             opacity: 0.6;
    //         }
    //     }
    // }
    .flex-between{
        display: flex;
        justify-content: space-between;
    }
    .title-big{
        color: #0161ad;
        text-align: left;
        font-size: 1.05rem;
        font-weight: bold;
        letter-spacing: 2px;
    }
    .title-small{
        color: #87c3ff;
        text-align: left;
        font-size: 1.05rem;
        font-weight: lighter;
        letter-spacing: -1px;
        margin-bottom: 2px;
    }
    .setting-icon{
        font-size: 1.5rem;
        color: #0161ad;
    }
}
</style>