const homeIndex = () => import ('@/views/rxroad/Index.vue');
const list = () => import ('@/views/rxroad/list/Index.vue');
const home = () => import ('@/views/rxroad/home/Index.vue');
const alarm = () => import ('@/views/rxroad/alarm/Index.vue');
const history = () => import ('@/views/rxroad/history/Index.vue');
const about = () => import ('@/views/rxroad/about/Index.vue');

const rxroadRouter = [
    { path: '/rxroad',component: homeIndex,children:[
        { path: '/',component: list,meta:{title:'荣县道路监测'}},
        { path: 'home',component: home},
        { path: 'alarm',component: alarm},
        { path: 'history',component: history},
        { path: 'about',component: about},
    ]}
]

export default rxroadRouter
