const homeIndex = () => import ('@/views/tunnel/Index.vue');
const project = () => import ('@/views/tunnel/project/Index.vue');
const home = () => import ('@/views/tunnel/home/Index.vue');
const electric = () => import ('@/views/tunnel/module/Electric.vue');
const traffic = () => import ('@/views/tunnel/module/Traffic.vue');
const video = () => import ('@/views/tunnel/module/Video.vue');
const illumination = () => import ('@/views/tunnel/module/Illumination.vue');
const ventilate = () => import ('@/views/tunnel/module/Ventilate.vue');
const fireprotection = () => import ('@/views/tunnel/module/Fireprotection.vue');
const broadcast = () => import ('@/views/tunnel/module/Broadcast.vue');
const record = () => import ('@/views/tunnel/module/Record.vue');
const alarm = () => import ('@/views/tunnel/module/Alarm.vue');


const tunnelRouter = [
    { path: '/tunnel',component: homeIndex,children:[
        { path: '/',component: project,meta:{title:'智慧隧道管理平台'}},
        { path: 'home',component: home},
        { path: 'electric',component: electric},
        { path: 'traffic',component: traffic},
        { path: 'video',component: video},
        { path: 'illumination',component: illumination},
        { path: 'ventilate',component: ventilate},
        { path: 'fireprotection',component: fireprotection},
        { path: 'broadcast',component: broadcast},
        { path: 'record',component: record},
        { path: 'alarm',component: alarm}
    ]}
]

export default tunnelRouter
