// weixin打包APPID配置信息

let appId,redirectUrl;

switch (process.env.NODE_ENV) {
    case "development":
        console.log('开发环境');
        appId = 'wx94352197b849c991';
        redirectUrl = encodeURIComponent("http://wx.ahdcloud.com/weixin/wechat/login/"+appId);
        //测试appid---王
        // appId = 'wx332554cdbe3778f2';
        // redirectUrl = encodeURIComponent("http://f4h2vc.natappfree.cc/weixin/wechat/login/"+appId);
        break;
    case "production":
        console.log('生产环境');
        // 欧齐珞 http://wx.ahdcloud.com/
        // appId = 'wx94352197b849c991';
        // redirectUrl = encodeURIComponent("http://wx.ahdcloud.com/weixin/wechat/login/"+appId);
        // 新科汇智
        appId = 'wxec55fd220e7c4b5a';
        redirectUrl = encodeURI("http://app.xk1510.com/weixin/wechat/login/"+appId);
        break;
    default:
        console.log('测试环境');
    break;
}

export {
    appId,
    redirectUrl
}