const homeIndex = () => import('../views/bridge/Index.vue');
const project = () => import('../views/bridge/page/Project.vue');
// const video = () => import('../views/bridge/page/Video.vue');

// 灯塔大桥
const lighthouseIndex = () =>import('../views/bridge/page/lighthouse/Index.vue');
const lighthouseHome = () => import('../views/bridge/page/lighthouse/Home.vue');
const lighthouseRealtime = () => import('../views/bridge/page/lighthouse/RealTime.vue');
const lighthouseWarning = () => import('../views/bridge/page/lighthouse/Warning.vue');
const lighthouseReport = () => import('../views/bridge/page/lighthouse/Report.vue');
const lighthouseAbout = () => import('../views/bridge/page/lighthouse/About.vue');
const lighthouseSensor = () => import('../views/bridge/page/lighthouse/Sensor.vue');
const lighthouseGateway = () => import('../views/bridge/page/lighthouse/Gateway.vue');

// 牛佛大桥
const niufoIndex = () =>import('../views/bridge/page/niufo/Index.vue');
const niufoHome = () => import('../views/bridge/page/niufo/Home.vue');
const niufoRealtime = () => import('../views/bridge/page/niufo/RealTime.vue');
const niufoWarning = () => import('../views/bridge/page/niufo/Warning.vue');
const niufoReport = () => import('../views/bridge/page/niufo/Report.vue');
const niufoAbout = () => import('../views/bridge/page/niufo/About.vue');
const niufoSensor = () => import('../views/bridge/page/niufo/Sensor.vue');
const niufoGateway = () => import('../views/bridge/page/niufo/Gateway.vue');

// 北京中山新技术设备研究所
const bjLabIndex = () =>import('../views/bridge/page/bjLab/Index.vue');
const bjLabHome = () => import('../views/bridge/page/bjLab/Home.vue');
const bjLabRealtime = () => import('../views/bridge/page/bjLab/RealTime.vue');
const bjLabWarning = () => import('../views/bridge/page/bjLab/Warning.vue');
const bjLabReport = () => import('../views/bridge/page/bjLab/Report.vue');
const bjLabAbout = () => import('../views/bridge/page/bjLab/About.vue');
const bjLabSensor = () => import('../views/bridge/page/bjLab/Sensor.vue');
const bjLabGateway = () => import('../views/bridge/page/bjLab/Gateway.vue');

// 新版桥梁-集成水位精灵、气象精灵、传感器、相机四大模块
const newVersionIndex = () =>import('../views/bridge/page/newVersion/Index.vue');
const newVersionHome = () => import('../views/bridge/page/newVersion/Home.vue');
const newVersionRealtime = () => import('../views/bridge/page/newVersion/RealTime.vue');
const newVersionWarning = () => import('../views/bridge/page/newVersion/Warning.vue');
const newVersionReport = () => import('../views/bridge/page/newVersion/Report.vue');
const newVersionSensorData = () => import('../views/bridge/page/newVersion/SensorData.vue');
const newVersionWeatherData = () => import('../views/bridge/page/newVersion/WeatherData.vue');
const newVersionWaterData = () => import('../views/bridge/page/newVersion/WaterData.vue');
const newVersionGateway = () => import('../views/bridge/page/newVersion/Gateway.vue');
const newVersionMedia = () => import('../views/bridge/page/newVersion/Media.vue');
const newVersionVideo = () => import('../views/bridge/page/newVersion/Video.vue');

const brudgeRouter = [
  {path: '/bridge',component: homeIndex,meta:{title:'智慧桥梁健康监控系统'},children:[
    {path: '/',component: project},
    // {path: '/video',name: 'Video',component: video},
    {path: 'lighthouse',component: lighthouseIndex,children:[
      {path: 'home',name: 'lighthouseHome',component: lighthouseHome},
      {path: 'realtime',name: 'lighthouseRealtime',component: lighthouseRealtime},
      {path: 'warning',name: 'lighthouseWarning',component: lighthouseWarning},
      {path: 'report',name: 'lighthouseReport',component: lighthouseReport},
      {path: 'about',name: 'lighthouseAbout',component: lighthouseAbout},
      {path: 'sensor',name: 'lighthouseSensor',component: lighthouseSensor},
      {path: 'gateway',name: 'lighthouseGateway',component: lighthouseGateway}
    ]},
    {path: 'niufo',component: niufoIndex,children:[
      {path: 'home',name: 'niufoHome',component: niufoHome},
      {path: 'realtime',name: 'niufoRealtime',component: niufoRealtime},
      {path: 'warning',name: 'niufoWarning',component: niufoWarning},
      {path: 'report',name: 'niufoReport',component: niufoReport},
      {path: 'about',name: 'niufoAbout',component: niufoAbout},
      {path: 'sensor',name: 'niufoSensor',component: niufoSensor},
      {path: 'gateway',name: 'niufoGateway',component: niufoGateway}
    ]},
    {path: 'bjLab',component: bjLabIndex,children:[
      {path: 'home',name: 'bjLabHome',component: bjLabHome},
      {path: 'realtime',name: 'bjLabRealtime',component: bjLabRealtime},
      {path: 'warning',name: 'bjLabWarning',component: bjLabWarning},
      {path: 'report',name: 'bjLabReport',component: bjLabReport},
      {path: 'about',name: 'bjLabAbout',component: bjLabAbout},
      {path: 'sensor',name: 'bjLabSensor',component: bjLabSensor},
      {path: 'gateway',name: 'bjLabGateway',component: bjLabGateway}
    ]},
    {path: 'newVersion',component: newVersionIndex,children:[
      {path: 'home',name: 'newVersionHome',component: newVersionHome},
      {path: 'realtime',name: 'newVersionRealtime',component: newVersionRealtime},
      {path: 'warning',name: 'newVersionWarning',component: newVersionWarning},
      {path: 'report',name: 'newVersionReport',component: newVersionReport},
      {path: 'sensor',name: 'newVersionSensor',component: newVersionSensorData},
      {path: 'weather',name: 'newVersionWeather',component: newVersionWeatherData},
      {path: 'water',name: 'newVersionWater',component: newVersionWaterData},
      {path: 'gateway',name: 'newVersionGateway',component: newVersionGateway},
      {path: 'media',name: 'newVersionMedia',component: newVersionMedia},
      {path: 'video',name: 'newVersionVideo',component: newVersionVideo}
    ]}
  ]}
]

export default brudgeRouter