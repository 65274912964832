const homeIndex = () => import ('@/views/720cam/Index.vue');
const home = () => import ('@/views/720cam/home/Home.vue');
const list = () => import ('@/views/720cam/dev/List.vue');
const detail = () => import ('@/views/720cam/dev/Detail.vue');
const history = () => import ('@/views/720cam/history/Index.vue');
const about = () => import('@/views/720cam/about/Index.vue');

const camRouter = [
    { path: '/720cam',component: homeIndex,children:[
        { path: '/',name: 'HomeCam',meta:{title:'围界监测'},component: home},
        { path: 'list',name: 'List',component: list},
        { path: 'detail',name: 'Detail',component: detail},
        { path: 'history',name: 'history',component: history},
        { path: 'about',component: about},
    ]}
]

export default camRouter
