const homeIndex = () => import ('@/views/wlms/Index.vue');
const list = () => import ('@/views/wlms/list/Index.vue');
const home = () => import ('@/views/wlms/home/Index.vue');
const analyse = () => import ('@/views/wlms/analyse/Index.vue');
const warning = () => import ('@/views/wlms/warning/Index.vue');
const setting = () => import ('@/views/wlms/setting/Index.vue');
const about = () => import ('@/views/wlms/about/Index.vue');
const explain = () => import ('@/views/wlms/explain/Index.vue');

const wlmsRouter = [
    { path: '/wlms',component: homeIndex,children:[
        { path: '/',component: list,meta:{title:'智慧水位精灵系统'}},
        { path: 'home',component: home,meta:{title:'智慧水位精灵系统'}},
        { path: 'analyse',component: analyse},
        { path: 'warning',component: warning},
        { path: 'setting',component: setting},
        { path: 'about',component: about},
        { path: 'explain',component: explain}
    ]}
]

export default wlmsRouter
