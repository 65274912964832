import { post , get } from '../request'

const bucket = {
    // 获取设备列表
    deviceList:(data) => post('/bucket/deviceBaseInfo/query',data),
    // 读取设备参数
    readParam:(data) => post('/bucket/exec/control/readParam',data),
    // 写入设备参数
    writeParam:(data) => post('/bucket/exec/control/writeParam',data),
    // 节目单字典信息
    programInfo:(data) => get('/bucket/sysDictData/info/'+data),
    // 重启设备
    rebootStm:(data) => post('/bucket/exec/control/rebootStm',data),
    // 单一设备远程告警及消警
    remoteOneDevAlarm:(data) => post('/bucket/exec/control/remoteOneDevAlarm',data),
    // 获取定位设备信息（锥桶）
    deviceNewInfo:(data) => post('/bucket/exec/devicePositionStatus/getLast',data),
    // 获取定位设备信息（肩灯）
    LampNewInfo:(data) => post('/bucket/exec/deviceLampStatus/getLast',data),
    // 本组设备告警
    remoteAlarm:(data) => post('/bucket/exec/control/remoteAlarm',data),
}

export default bucket
