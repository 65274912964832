import { post , get , put} from '../request'

const swjl = {
     // 全部设备
     getAllDev:(data) => post('/swjl/deviceBaseInfo/query',data),
     // 历史图片数据
     filePathPage:(pageNum,pageSize,data) => post(`/swjl/filePath/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
     //电压曲线
     batChartsData:(data) => post('/swjl/exec/minidevsta/echarts',data),
     //相机设备信息
     cameraInfo:(data) => post('/camera/deviceBaseInfo/query',data),
     //相机电压曲线
     cameraBatChartsData:(data) => post('camera/exec/heart/echarts',data),
     // 历史图片数据
     cameraFilePathPage:(pageNum,pageSize,data) => post(`/camera/filePath/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
}

export default swjl
