const homeIndex = () => import ('@/views/geom/Index.vue');
const home = () => import ('@/views/geom/home/Index.vue');
const statistics = () => import ('@/views/geom/statistics/Index.vue');
const history = () => import ('@/views/geom/history/Index.vue');


const geomRouter = [
    { path: '/geom',component: homeIndex,children:[
        { path: '/',component: home,meta:{title:'地埋式车检器'}},
        { path: 'statistics',component: statistics},
        { path: 'history',component: history},
    ]}
]

export default geomRouter
