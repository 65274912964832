const homeIndex = () => import('../views/slope/Index.vue');
const project = () => import('../views/slope/page/Project.vue');
const video = () => import('../views/slope/page/Video.vue');
// 荣县边坡1
const rongxian1Index = () =>import('../views/slope/page/rongxian1/Index.vue');
const rongxian1Home = () => import('../views/slope/page/rongxian1/Home.vue');
const rongxian1Realtime = () => import('../views/slope/page/rongxian1/RealTime.vue');
const rongxian1Warning = () => import('../views/slope/page/rongxian1/Warning.vue');
const rongxian1Report = () => import('../views/slope/page/rongxian1/Report.vue');
const rongxian1About = () => import('../views/slope/page/rongxian1/About.vue');
const rongxian1Sensor = () => import('../views/slope/page/rongxian1/Sensor.vue');
const rongxian1Gateway = () => import('../views/slope/page/rongxian1/Gateway.vue');
const rongxian1Wind = () => import('../views/slope/page/rongxian1/Wind.vue');
const rongxian1Temperature = () => import('../views/slope/page/rongxian1/Temperature.vue');
// const rongxian1Video = () => import('../views/slope/page/rongxian1/Video.vue');
// 荣县边坡2-白岩石边坡
const rongxian2Index = () =>import('../views/slope/page/baiyanshi/Index.vue');
const rongxian2Home = () => import('../views/slope/page/baiyanshi/Home.vue');
const rongxian2Realtime = () => import('../views/slope/page/baiyanshi/RealTime.vue');
const rongxian2Warning = () => import('../views/slope/page/baiyanshi/Warning.vue');
const rongxian2Report = () => import('../views/slope/page/baiyanshi/Report.vue');
const rongxian2About = () => import('../views/slope/page/baiyanshi/About.vue');
const rongxian2Sensor = () => import('../views/slope/page/baiyanshi/Sensor.vue');
const rongxian2Gateway = () => import('../views/slope/page/baiyanshi/Gateway.vue');
const rongxian2Wind = () => import('../views/slope/page/baiyanshi/Wind.vue');
const rongxian2Temperature = () => import('../views/slope/page/baiyanshi/Temperature.vue');
// const rongxian2Video = () => import('../views/baiyanshi/Video.vue');

// 荣县边坡3-新白岩石边坡
const rongxian3Index = () =>import('../views/slope/page/xinbaiyanshi/Index.vue');
const rongxian3Home = () => import('../views/slope/page/xinbaiyanshi/Home.vue');
const rongxian3Realtime = () => import('../views/slope/page/xinbaiyanshi/RealTime.vue');
const rongxian3Warning = () => import('../views/slope/page/xinbaiyanshi/Warning.vue');
const rongxian3Report = () => import('../views/slope/page/xinbaiyanshi/Report.vue');
const rongxian3About = () => import('../views/slope/page/xinbaiyanshi/About.vue');
const rongxian3Sensor = () => import('../views/slope/page/xinbaiyanshi/Sensor.vue');
const rongxian3Gateway = () => import('../views/slope/page/xinbaiyanshi/Gateway.vue');
const rongxian3Wind = () => import('../views/slope/page/xinbaiyanshi/Wind.vue');
const rongxian3Temperature = () => import('../views/slope/page/xinbaiyanshi/Temperature.vue');

// 新版桥梁-集成气象精灵、传感器、相机三大模块
const newVersionIndex = () =>import('../views/slope/page/newVersion/Index.vue');
const newVersionHome = () => import('../views/slope/page/newVersion/Home.vue');
const newVersionRealtime = () => import('../views/slope/page/newVersion/RealTime.vue');
const newVersionWarning = () => import('../views/slope/page/newVersion/Warning.vue');
const newVersionReport = () => import('../views/slope/page/newVersion/Report.vue');
const newVersionSensorData = () => import('../views/slope/page/newVersion/SensorData.vue');
const newVersionWeatherData = () => import('../views/slope/page/newVersion/WeatherData.vue');
const newVersionGateway = () => import('../views/slope/page/newVersion/Gateway.vue');
const newVersionMedia = () => import('../views/slope/page/newVersion/Media.vue');
const newVersionVideo = () => import('../views/slope/page/newVersion/Video.vue');

const slopeRouter = [
  {path: '/slope',component: homeIndex,meta:{title:'边坡健康监测系统'},children:[
    {path: '/',component: project,meta:{title:'边坡健康监测系统'}},
    // 荣县边坡1
    {path: 'rongxian',component: rongxian1Index,children:[
      {path: 'home',meta:{title:'荣县边坡监测系统'},component: rongxian1Home},
      {path: 'realtime',component: rongxian1Realtime},
      {path: 'warning',component: rongxian1Warning},
      {path: 'report',component: rongxian1Report},
      {path: 'about',component: rongxian1About},
      {path: 'sensor',component: rongxian1Sensor},
      {path: 'gateway',component: rongxian1Gateway},
      {path: 'wind',component: rongxian1Wind},
      {path: 'temperature',component: rongxian1Temperature},
    ]},
    // 荣县边坡2-白岩石边坡
    {path: 'baiyanshi',component: rongxian2Index,children:[
      {path: 'home',meta:{title:'白岩石边坡监测系统'},component: rongxian2Home},
      {path: 'realtime',component: rongxian2Realtime},
      {path: 'warning',component: rongxian2Warning},
      {path: 'report',component: rongxian2Report},
      {path: 'about',component: rongxian2About},
      {path: 'sensor',component: rongxian2Sensor},
      {path: 'gateway',component: rongxian2Gateway},
      {path: 'wind',component: rongxian2Wind},
      {path: 'temperature',component: rongxian2Temperature},
      // {path: 'video',name: 'video',component: rongxian2Video},
    ]},
    {path: 'xinbaiyanshi',component: rongxian3Index,children:[
      {path: 'home',meta:{title:'新白岩石边坡监测系统'},component: rongxian3Home},
      {path: 'realtime',component: rongxian3Realtime},
      {path: 'warning',component: rongxian3Warning},
      {path: 'report',component: rongxian3Report},
      {path: 'about',component: rongxian3About},
      {path: 'sensor',component: rongxian3Sensor},
      {path: 'gateway',component: rongxian3Gateway},
      {path: 'wind',component: rongxian3Wind},
      {path: 'temperature',component: rongxian3Temperature},
    ]},
    {path: 'newVersion',component: newVersionIndex,children:[
      {path: 'home',name: 'newVersionHome',component: newVersionHome},
      {path: 'realtime',name: 'newVersionRealtime',component: newVersionRealtime},
      {path: 'warning',name: 'newVersionWarning',component: newVersionWarning},
      {path: 'report',name: 'newVersionReport',component: newVersionReport},
      {path: 'sensor',name: 'newVersionSensor',component: newVersionSensorData},
      {path: 'weather',name: 'newVersionWeather',component: newVersionWeatherData},
      {path: 'gateway',name: 'newVersionGateway',component: newVersionGateway},
      {path: 'media',name: 'newVersionMedia',component: newVersionMedia},
      {path: 'video',name: 'newVersionVideo',component: newVersionVideo}
    ]},
    {path: 'video',component: video},
  ]}
]

export default slopeRouter
