import router from '../router'
import { getParam } from '../util/index.js'
import API from '@/network/index.js'

// 路由守卫
router.beforeEach((to, from, next) => {
    /* 路由发生改变修改页面的title */
    if(to.meta.title) {
        document.title = to.meta.title
    }
    // 统一sso回调逻辑
    const TOKEN =  window.localStorage.getItem('Tit-token');
    if(to.path === '/' && !TOKEN){
        // 判断token是否有效
        API.SSO.isLogin().then(result =>{
            if(result.isLogin){
                window.localStorage.setItem('Tit-token',result.tokenValue);
                next()
            }else{
                if (location.search&&location.search.includes('openId')){
                    const OPENID = getParam('openId')
                    localStorage.setItem('OPENID',OPENID);
                    if(OPENID && OPENID !== 'null'){
                        // 检查openid是否绑定用户
                        API.SSO.isBindingByOpenId({ openId:OPENID }).then( isBind =>{
                            if(isBind){
                                // 已绑定，换取Tit-token
                                API.SSO.doWxLoginByOpenId({ openId:OPENID }).then( token =>{
                                    window.localStorage.setItem('Tit-token', token);
                                    next()
                                })
                            }else{
                                history.replaceState({},'',location.href.replace(location.search,''))
                                console.log(isBind)
                                // 未绑定，跳转登录页
                                next({path:'/login'})
                            }
                        })
                    }else{
                        next({path:'/login'})
                    }
                }else{
                    next();
                }
            }
        })
    }else{
        next();
    }
})