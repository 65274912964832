import { post , get } from '../request'

const rxroad = {
    //获取该项目下所有路段信息
    getRoadInfo:(data) => post("/auth/sysRoad/list", data),
    //最新设备列表信息接口
    getMelveDevInfo:(data) => post('/melves/exec/deviceBaseInfo/getDevStatus',data),
    //获取该项目下所有摄像头信息
    getCameraList:(data) => post('/camera/deviceBaseInfo/query',data),
    //获取该项目下告警信息
    alarmInfoPage:(pageNum,pageSize,data) => post(`/melves/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    
    // 设备信息列表
    deviceInfoList:(data) => post('/jcw/exec/wxDeviceBaseInfo/getDevStatus',data),
    // 历史数据
    historyInfoPage:(pageNum,pageSize,data) => post(`/jcw/jcwMonitorCollection/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 根据设备编号查询实时信息
    realInfoByDevCode:(devCode) => get(`/jcw/exec/jcwMonitorCollection/get/${devCode}`),
    // 告警列表
    alarmPageList:(pageNum,pageSize,data) => post(`/jcw/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 处理告警
    handleAlarm:(data) => post(`/alarm/cancelTips`,data)
}

export default rxroad
