import { post , get , put} from '../request'

const wgs = {
     // 全部设备
     getAllDev:(data) => post('/wgs/deviceBaseInfo/query',data),
     // 设备分页列表
     getDevicePageList:(pageNum,pageSize,data) => post(`/wgs/deviceBaseInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data), 
     // 分页查询告警信息
     warningPageList:(pageNum,pageSize,data) => post(`/wgs/wgsAlarmData/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
     // 单个设备信息
     deviceSingleInfo:(data) => post('/wgs/exec/wgsWeChatApps/getDevStatus',data),
     // 统计信息
     statisticsInfo:(data) => post('/wgs/exec/wgsWeChatApps/getStatistics',data),
     // 获取最新告警一条
     newAlarmLastOne:(data) => post('/wgs/exec/wgsAlarmData/getLastOne',data),
     // 获取最新告警一条
     updateAlarmItem:(data) => put('/wgs/deviceBaseInfo/update',data),
     // 获取告警图表数据
     getDateCharts:(data) => post('/wgs/wgsAlarmReserveData/query',data),
     // 获取全部路段信息列表
     getRoad:(data) => post('/auth/sysRoad/list',data),
}

export default wgs
