import { post , get } from '../request'

const melves = {
    // 最新设备列表信息接口
    newDevInfo:(data) => post('/melves/exec/deviceBaseInfo/getDevStatus',data),
    // 统计图表接口
    statisticsInfo:(data) => post('/melves/exec/roadStatus/getDevicesInfo',data),
    // 所有设备列表接口
    allDevInfo:(data) => post('/melves/deviceBaseInfo/query',data),
    // 气象数据历史
    roadStatusData:(pageNum,pageSize,data) => post(`/melves/roadStatus/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 告警历史
    deviceAlarmInfoData:(pageNum,pageSize,data) => post(`/melves/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 设备状态历史
    deviceStatusData:(pageNum,pageSize,data) => post(`/melves/deviceStatus/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 查询首页数据
    homeCount:(data) => post('/main/melves/getStatusCount',data),
    // ===================================================== M2新协议 ====================================================================
    // 统计图表接口
    statisticsInfoM2:(data) => post('/melves/exec/status.M2/getDevicesInfo',data),
    // 历史数据
    deviceHistoryM2:(pageNum,pageSize,data) => post(`/melves/status.M2/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),

}

export default melves
