import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import '@/authority/index.js'
import API from '@/network/index.js'
import 'vant/lib/index.css';
import "font-awesome/css/font-awesome.min.css";

Vue.use(Vant);
Vue.prototype.$api=API;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
