const homeIndex = () => import ('@/views/icps/Index.vue');
const home = () => import ('@/views/icps/home/Home.vue');
const devIndex = () => import ('@/views/icps/dev/Index.vue');
const devList = () => import ('@/views/icps/dev/List.vue');
const devDetail = () => import('@/views/icps/dev/Detail.vue');
// const devVideo = () => import('@/views/icps/dev/Video.vue');
const warn = () => import('@/views/icps/warning/Index.vue');
const about = () => import('@/views/icps/about/Index.vue');
const explain = () => import('@/views/icps/about/Explain.vue');
const intro = () => import('@/views/icps/about/Intro.vue');

const icpsRouter = [
    {path: '/icps',component: homeIndex,meta:{title:'智能供电包系统'},children:[
        { path: '/',component: home,meta:{title:'智能供电包系统'}},
        { path: 'dev',component: devIndex,children:[
            { path: '/',component: devList,meta:{title:'设备列表'}},
            { path: 'detail',component: devDetail,meta:{title:'设备详情'}},
            // { path: 'video',component: devVideo,meta:{title:'实时视频'}},
        ]},
        { path: 'warn',component: warn,meta:{title:'告警信息'}},
        { path: 'about',component: about,meta:{title:'关于'}},
        { path: 'explain',component: explain,meta:{title:'产品说明'}},
        { path: 'intro',component: intro,meta:{title:'产品介绍'}},
    ]}
]

export default icpsRouter