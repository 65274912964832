import { post , get } from '../request'

const bridge = {
    //==============================首页部分===========================
    // getVisitor:(url) => get('/wx/wxUserVsBridge/visitor/'+url),
    // getProjectPage:(data) => post('/wx/tBridgeInfo/page',data),
    getProjectPage:(data) => post('/bhms/deviceBaseInfo/query',data),
    getDevCount:(data) => post('/bhms/exec/bhmsModuleInfo/getNumByBriCode',data),
    getHealth:(data) => post('/bhms/exec/bhmsBridgeHealth/getByBriCode',data),
    // 获取气象数据
    getEnv:(data) => post('/bhms/exec/bhmsDataEnv/getEnvByDev',data),
    // ==============================tab栏==============================
    getAlarmNum:(data) => post('/bhms/exec/deviceAlarmInfo/getAlarmNum',data),
    // ==============================实时监控==============================
    realTimeList:(data) => post('/bhms/exec/bhmsDataVibrateDisplay/getListByBriCode',data),
    getWeather:(data) => post('/bhms/exec/bhmsWeather/getWeather',data),
    getModuleInfo:(data) => post('/bhms/bhmsModuleInfo/query',data),
    // ==============================告警数据==============================
    AlarmDataPage:(pageNum,pageSize,data) => post('/bhms/deviceAlarmInfo/page?pageNum='+pageNum+'&pageSize='+pageSize,data),
    saveAlarmData:(data) => post('/bhms/exec/deviceAlarmInfo/saveNotes',data),
    // ==============================图表报告==============================
    statChart:(data) => post('/bhms/exec/bhmsDataVibrateDisplay/getStat',data),
    // ==============================视频流==============================
    getHls:(data) => post('/bhms/exec/bhmsVideoHsl/getHls',data),
    // ==============================设备信息==============================
    // sensorData:(url) => get('/bhms/exec/dVibrateDisplay/getTableData/'+url),
    geteWayData:(data) => post('/bhms/exec/bhmsDataGate/getLastOne',data),
};
export default bridge;