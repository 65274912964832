import axios from 'axios'
axios.defaults.baseURL='/weixin';

// 错误自己消化，正确往下传递
const get = (path,config) => {
    if(config === 'videoserver'){
        axios.defaults.baseURL='/videoserver'
    }else{
        axios.defaults.baseURL='/weixin'
    }
    return new Promise((resolve)=>{
        axios.get(path,config).then((res)=>{
            if(res.data === 0){
                resolve(res.data)
                return
            }
            resolve(res.data || res.rows || null)
        }).catch((err)=>{
            console.log('响应小错误：',err.data)
        })
    })
}
// 错误自己消化，正确往下传递
const post = (path,config) => {
    if(path === '/camera/command'){
        axios.defaults.baseURL='/tcpserver'
    }else{
        axios.defaults.baseURL='/weixin'
    }
    return new Promise((resolve)=>{
        axios.post(path,config).then((res)=>{
            if(res.data === 0){
                resolve(res.data)
                return
            }
            resolve(res.data || res.rows || null)
        }).catch((err)=>{
            console.log('响应小错误：',err.data)
        })
    })
}

// 错误自己消化，正确往下传递
const put = (path,config) => {
    return new Promise((resolve)=>{
        axios.put(path,config).then((res)=>{
            if(res.data === 0){
                resolve(res.data)
                return
            }
            resolve(res.data || res.rows || null)
        }).catch((err)=>{
            console.log('响应小错误：',err.data)
        })
    })
}

export {get, post, put}