const homeIndex = () => import ('@/views/fog/Index.vue');
const home = () => import ('@/views/fog/home/Home.vue');
const list = () => import ('@/views/fog/dev/List.vue');
const detail = () => import ('@/views/fog/dev/Detail.vue');
const history = () => import ('@/views/fog/history/Index.vue');
const about = () => import('@/views/fog/about/Index.vue');

const camRouter = [
    { path: '/fog',component: homeIndex,children:[
        { path: '/',name: 'HomeFog',meta:{title:'团雾监测'},component: home},
        { path: 'list',name: 'List',component: list},
        { path: 'detail',name: 'Detail',component: detail},
        { path: 'history',name: 'history',component: history},
        { path: 'about',component: about},
    ]}
]

export default camRouter
