import { post , get , put} from '../request'

const fog = {
     // 全部设备
     getAllDev:(data) => post('/fog/deviceBaseInfo/query',data),
     // 历史数据
     fogMonitorDevstaPage:(pageNum,pageSize,data) => post(`/fog/fogMonitorDevsta/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
     //相机推流
     cameraPlugFlow:(data) => post('/fog/deviceVideoStatus',data),
}

export default fog
