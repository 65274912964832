import { post , get } from '../request'

const wlms = {
    // 获取场景选项
    getSceneOption:(data) => get('/wlms/exec/wlmsConfig/getScene',data),
    // 获取水位变化趋势
    wlmsChartsData:(data) => post('/wlms/exec/wlmsStatus/getDevicesInfo',data),
    // 保存设备设置
    cofingSave:(data) => post('/wlms/wlmsConfig/save',data),
    // 获取配置项回显
    echoConfig:(data) => post('/wlms/exec/wlmsConfig/getConfig',data),
    // 获取当前场景
    currentScene:(data) => post('/wlms/exec/wlmsConfig/getCurScene',data),
    // 获取实时天气
    weatherInfo:(data) => post('/wlms/exec/wlmsConfig/getWeather',data),
    // 告警分页
    alarmPage:(pageNum,pageSize,data) => post(`/wlms/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 处理告警
    handleAlarm:(data) => post('/wlms/exec/deviceAlarmInfo/cancel',data),
    // 设备列表
    deviceList:(data) => post('/wlms/deviceBaseInfo/query',data),
    // 设备信息-水位信息
    deviceMonitorInfo:(data) => post('/wlms/exec/deviceBaseInfo/getDevStatus',data)
}

export default wlms
