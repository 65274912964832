import { post , get } from '../request'

const pah = {
     //==============================首页部分===========================
     // getUserAuth:(params) => get('/wx/sfmsWxUserVsProject/visitor',{params}),// 获取用户权限游客
     getAllDev:(params) => post('/pah/deviceBaseInfo/query',params),
     //==============================设备部分===========================
     // 根据权限获取项目列表
     // getProject:(data) => get('/wx/sfmsProject/getProjectsByUser',data),
     // 根据项目id获取设备列表
     // getProjectDev:(data) => post('/wx/sfmsDevices/getDevByProjectId/'+data),
     getCount:(params) => post('/pah/exec/deviceBaseInfo/getCount',params),
     // getDevPage:(params) => post('/pah/monitorDevice/page',params),
     getLastOne:(params) => post('/pah/exec/monitorDevice/getLastOne',params),
     //==============================设备详情===========================
     // devSystem:(url) => get('/wx/sfmsDataSystem/getByDevCode/'+url), //获取系统设置
     devSystem:(params) => post('/pah/exec/dataSystem/getLastOne',params), //获取系统设置
     devSystemSave:(data) => post('/pah/exec/controlOrder/system',data), //保存系统设置
     devworkMode:(params) => post('/pah/exec/dataWorkMode/getLastOne',params), //获取工作模式数据
     devworkModeSave:(data) => post('/pah/exec/controlOrder/workMode',data), //保存工作模式
     devRouter:(params) => post('/pah/exec/dataRouter/getLastOne',params), //获取路由控制数据
     devRouterSave:(data) => post('/pah/exec/controlOrder/openRouter',data), //保存路由控制数据
     devProjection:(params) => post('/pah/exec/dataProjection/getLastOne',params), //获取投影控制数据
     devProjectionSave:(data) => post('/pah/exec/controlOrder/projection',data), //保存投影控制数据
     devRadar:(params) => post('/pah/exec/dataRadar/getLastOne',params), //获取雷达控制模式
     devRadarSave:(data) => post('/pah/exec/controlOrder/radar',data), //保存雷达控制模式
     devSpeaker:(params) => post('/pah/exec/dataSpeaker/getLastOne',params), //获取播报控制数据
     devSpeakerSave:(data) => post('/pah/exec/controlOrder/speaker',data), //保存保存控制数据
     getProgram:(data) => post('/pah/exec/pahProgram/query',data), // 获取语音控制节目列表
     BatteryCurve:(data) => post('/pah/exec/monitorDevice/batPowList',data), //获取设备电池曲线
}

export default pah
