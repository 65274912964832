import { post , get } from '../request'

const newBridge = {
    //==============================首页部分===========================
    // 获取数量
    bhmsCount:(data) => post('/main/bridge/getStatusCount',data),
    slopeCount:(data) => post('/main/slope/getStatusCount',data),
    melvesCount:(data) => post('/main/melves/getStatusCount',data),
    wlmsCount:(data) => post('/main/wlms/getStatusCount',data),
    cameraCount:(data) => post('/main/camera/getStatusCount',data),
    getHealth:(data) => post('/bhms/exec/bhmsBridgeHealth/getByBriCode',data),
    // 实时采集数据
    melvesNewData:(data) => post('/melves/exec/deviceBaseInfo/getDevStatus',data),
    wlmsNewData:(data) => post('/wlms/exec/deviceBaseInfo/getDevStatus',data),
    // 传感器详情
    sensorDetail:(data) => post('/bhms/exec/bhmsWeChat/getListCycleDataByProjectCode',data),
    sensorAllData:(data) => post('/bhms/exec/bhmsWeChat/getDevListByProjectCode',data),
    // 走势图
    sensorTrend:(data) => post('/bhms/exec/bhmsWeChat/getStat',data),
    tempAndHumiTrend:(data) => post('/melves/exec/status.M2/getDevicesInfo',data),
    wlmsTrend:(data) => post('/wlms/exec/wlmsStatus/getDevicesInfo',data),
    // 媒体文件
    cameraFilePage:(pageNum,pageSize,data) => post(`/camera/filePath/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 告警
    // bhmsWarningPage:(pageNum,pageSize,data) => post(`/bhms/exec/bhmsWeChat/AlarmDataList?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    bhmsWarningPage:(pageNum,pageSize,data) => post(`/bhms/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // bhmsWarningPage:(pageNum,pageSize,data) => post(`/alarm/bridge/getAlarmByProjectCode?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    
    melvesWarningPage:(pageNum,pageSize,data) => post(`/melves/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    wlmsWarningPage:(pageNum,pageSize,data) => post(`/wlms/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
    // 视频流
    cameraInfo:(data) => post('/camera/deviceBaseInfo/query',data),
    streamInfo:(data) => post('/camera/deviceVideoStatus',data),
    videoCommand:(data) => post('/camera/command',data),
    // h265转mp4
    h265toMp4:(url) => get(`/toMp4?url=${url}`,'videoserver'),
    // 获取桥梁or边坡点位图
    getinodeBitmap:(data) => post('/bhms/exec/bhmsBridgePic/query',data)
};
export default newBridge;