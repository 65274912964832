const homeIndex = () => import ('@/views/swjl/Index.vue');
const list = () => import ('@/views/swjl/dev/List.vue');
const detail = () => import ('@/views/swjl/dev/Detail.vue');
const batLine = () => import ('@/views/swjl/dev/BatLine.vue');

const camRouter = [
    { path: '/swjl',component: homeIndex ,children:[
        { path: '/',name: 'List',meta:{title:'守望精灵'},component: list},
        { path: 'detail',name: 'Detail',component: detail},
        { path: 'batline',name: 'BatLine',component: batLine}
    ]}
]

export default camRouter
