const homeIndex = () => import ('@/views/itgs/Index.vue');
const home = () => import ('@/views/itgs/home/Home.vue');
const location = () => import('@/views/itgs/location/Location.vue');
const about = () => import('@/views/itgs/about/About.vue');
const intro = () => import('@/views/itgs/about/Intro.vue');
const media = () => import('@/views/itgs/media/Media.vue');
const detail = () => import('@/views/itgs/home/Detail.vue');
const controlDev = () => import('@/views/itgs/home/ControlDev.vue');
const control = () => import('@/views/itgs/control/Control.vue');
const controlIndex = () => import('@/views/itgs/control/Index.vue');
const controlProgram = () => import('@/views/itgs/control/device/Program.vue');
const controlIllumination = () => import('@/views/itgs/control/device/Illumination.vue');
const controlPicture = () => import('@/views/itgs/control/device/Picture.vue');
const controlVideo = () => import('@/views/itgs/control/device/Video.vue');
const controlChat = () => import('@/views/itgs/control/device/Chat.vue');
const controlWarn = () => import('@/views/itgs/control/data/Warn.vue');
const controlStatus = () => import('@/views/itgs/control/data/Status.vue');
const controlWeather = () => import('@/views/itgs/control/data/Weather.vue');
const controlCar = () => import('@/views/itgs/control/data/Car.vue');
const histogramStatistics = () => import('@/views/itgs/control/statistics/Histogram.vue');
const lineStatistics = () => import('@/views/itgs/control/statistics/Line.vue');

const itgsRouter = [
    {path: '/itgs',component: homeIndex,meta:{title:'公路全功能智能终端'},children:[
        {path: '/',component: home,meta:{title:'公路全功能智能终端'}},
        {path: 'location',component: location,meta:{title:'公路全功能智能终端'}},
        {path: 'control',component: controlIndex,children:[
            {path: '/',component: control,meta:{title:'工作台'}},
            {path: 'program',component: controlProgram,meta:{title:'节目控制'}},
            {path: 'illumination',component: controlIllumination,meta:{title:'照明控制'}},
            {path: 'picture',component: controlPicture,meta:{title:'拍照控制'}},
            {path: 'video',component: controlVideo,meta:{title:'视频查看'}},
            {path: 'chat',component: controlChat,meta:{title:'语音对讲'}},
            {path: 'warn',component: controlWarn,meta:{title:'告警数据'}},
            {path: 'status',component: controlStatus,meta:{title:'设备状态'}},
            {path: 'weather',component: controlWeather,meta:{title:'气象数据'}},
            {path: 'car',component: controlCar,meta:{title:'过车数据'}},
            {path: 'histogram',component: histogramStatistics,meta:{title:'柱状图像'}},
            {path: 'line',component: lineStatistics,meta:{title:'折线图像'}}
        ]},
        {path: 'media',component: media,meta:{title:'媒体信息'}},
        {path: 'about',component: about,meta:{title:'关于'}},
        {path: 'intro',component: intro,meta:{title:'产品介绍'}},
        {path: 'detail',component: detail,meta:{title:'公路全功能智能终端'}},
        {path: 'controlDev',component: controlDev,meta:{title:'设备控制'}}
    ]}
]

export default itgsRouter