const homeIndex = () => import ('@/views/wgs/Index.vue');
const home = () => import ('@/views/wgs/home/Home.vue');
const list = () => import ('@/views/wgs/dev/List.vue');
const detail = () => import ('@/views/wgs/dev/Detail.vue');
const warning = () => import ('@/views/wgs/warning/Index.vue');
const chart = () => import ('@/views/wgs/warning/Chart.vue');
const about = () => import('@/views/wgs/about/Index.vue');

const wgsRouter = [
    { path: '/wgs',component: homeIndex,children:[
        { path: '/',name: 'HomeWgs',meta:{title:'智慧护栏'},component: home},
        { path: 'list',name: 'List',component: list},
        { path: 'detail',name: 'Detail',component: detail},
        { path: 'warning',name: 'Warning',component: warning},
        { path: 'chart',name: 'Chart',component: chart},
        { path: 'about',component: about},
    ]}
]

export default wgsRouter
