import { post , get } from '../request'

const icps = {
    // 设备列表
    devList:(data) => post('/icps/wxDeviceBaseInfo/page',data),
    devListPage:(pageNum,pageSize,data) => post('/icps/wxDeviceBaseInfo/page?pageNum='+pageNum+'&pageSize='+pageSize,data),
    
    // 设备数量
    devCount:(data) => post('/icps/exec/wxDeviceBaseInfo/getCounts',data),
    // 通过设备编号查询最新状态
    devNewInfo:(devCode) => get('/icps/exec/wxDeviceStatus/getLastStatus/'+devCode),
    // 设备自身信息
    devSelfInfo:(devCode) => get('/icps/exec/wxDeviceBaseInfo/info/'+devCode),
    // 通过设备编号查询近24小时剩余电量走势
    devBattery:(devCode) =>get('/icps/exec/wxDeviceStatus/getLineStatus/'+devCode),
    // 系统控制
    controlSystem:(name,data) => post('/icps/exec/control/'+name,data),
    // 告警列表
    alarmList:(pageNum,pageSize,data) => post('/icps/deviceAlarmInfo/page?pageNum='+pageNum+'&pageSize='+pageSize,data)
}

export default icps
