<template>
    <div class="no-page">
      <h1>敬请期待</h1>
      <p>该项目功能开发中，请稍后再来查看。</p>
      <button @click="goBack">返回</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NoPage',
    methods: {
      goBack() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style scoped>
  .no-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  

  
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #276ba0;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #489dec;
  }
  h1 {
            /* 本例12个文字(加标点符号)；有多少个文字，width就是多少个em */
            width: 12em;
            /* 加上两个动画，一个是打字动画，使用steps让字一个一个的出现，
             注意step和字数保持一致，光标动画也是同理，*/
            animation: typingWords 5s steps(12) infinite, cursor 0.5s steps(1) infinite;
            /* 要设置不允许换行，且溢出隐藏 */
            white-space: nowrap;
            overflow: hidden;
            /* 使用右边框作为打印的指针光标 */
            border-right: 1px solid #000;
        }

        @keyframes typingWords {
            0% {
                width: 0;
            }
        }

        @keyframes cursor {
            50% {
                border-color: transparent;
            }
        }
  </style>