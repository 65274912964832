import { post , get , put} from '../request'

const camera = {
     // 全部设备
     getAllDev:(data) => post('/camera/deviceBaseInfo/query',data),
     // 历史数据
     filePathPage:(pageNum,pageSize,data) => post(`/camera/filePath/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
     //相机推流
     cameraPlugFlow:(data) => post('/camera/deviceVideoStatus',data),
}

export default camera
